
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

a.visited {
    color: purple;
}

.loading {
    pointer-events: none;
}

.video-container.can-play {
    cursor: pointer;

    &::after {
        content: '▶';
        position: absolute;
        opacity: 0.5;
        color: white;
        text-shadow: 0 3px black;
        z-index: 100;
        font-size: 2em;
        top: calc(50% - 0.75em);
        left: calc(50% - 0.75em);
        width: 1.5em;
        height: 1.5em;
        transform: scale(1);
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }

    &:hover:after {
        opacity: 0.8;
        transform: scale(1.1);
        text-shadow: 0 3px #666;
    }

    video {
        background-color: $main-background;
    }
}
